* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#container {
    background: rgb(255, 192, 203);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    text-align: center;
}


.word {
    position: relative;
    text-align: center;
    width: 500px;
    height: 100px;
    margin: 0 auto;
    top: 100px;
    transform: translateY(-50%);
    font-family: 楷体, serif;
    font-size: 40px;
    color: #c70012;
}